import { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';
import dynamic from 'next/dynamic';

import type { TRouter } from '@local-types/global';
import type { StrapiBiasType, TagType } from '@local-types/data';

import ToolFooter from '@components/ToolFooter';

import Logos from '@components/Logos';

import useUXCoreGlobals from '@hooks/useUXCoreGlobals';
import ProductIcon from '@icons/ProductIcon';
import HrIcon from '@icons/HrIcon';
import FolderIcon from '@icons/FolderIcon';
import CoreIcon from '@icons/CoreIcon';

import styles from './UXCoreLayout.module.scss';
import Spinner from '@components/Spinner';
import Search from '@components/_biases/Search';
import useUCoreMobile from '@hooks/uxcoreMobile';
import { UserTypes } from '@local-types/uxcat-types/types';

const FolderViewLayout = dynamic(() => import('@layouts/FolderViewLayout'), {
  ssr: false,
});
const CoreViewLayout = dynamic(() => import('@layouts/CoreViewLayout'), {
  ssr: false,
});

const UXCorePopup = dynamic(() => import('@components/UXCorePopup'), {
  ssr: false,
});

const UXCoreSnackbar = dynamic(() => import('@components/UXCoreSnackbar'), {
  ssr: false,
});

const ViewSwitcher = dynamic(() => import('@components/_biases/ViewSwitcher'), {
  ssr: false,
});
const MobileView = dynamic(() => import('@components/_biases/MobileView'), {
  ssr: false,
});

interface UXCoreLayoutProps {
  tags: TagType[];
  strapiBiases: StrapiBiasType[];
  isOpen?: boolean;
  biasSelected?: boolean;
  openPodcast?: boolean;
  setOpenPodcast?: {
    (updater: (prev: boolean) => boolean): void;
    (value: boolean): void;
  };
  description?: string;
  userInfo?: UserTypes;
  setUserInfo?: (data: UserTypes) => void;
}

const UXCoreLayout: FC<UXCoreLayoutProps> = ({
  tags,
  strapiBiases,
  isOpen,
  biasSelected,
  openPodcast,
  setOpenPodcast,
  description,
  userInfo,
  setUserInfo,
}) => {
  const [{ toggleIsCoreView }, { isCoreView }] = useUXCoreGlobals();
  const [{ toggleIsProductView }, { isProductView }] = useUXCoreGlobals();
  const router = useRouter();
  const { asPath } = router as TRouter;
  const { isUxcoreMobile } = useUCoreMobile()[1];
  const [isLoaded, setIsLoaded] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const [isSwitched, setIsSwitched] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [headerPodcastOpen, setHeaderPodcastOpen] = useState(false);
  const { locale } = router as TRouter;
  const productViewLabel = locale === 'ru' ? 'Продукт' : 'Product';

  useEffect(() => {
    setIsLoaded(true);
  }, [router.events, asPath]);

  const browsingAsProduct =
    locale === 'en'
      ? 'You’re browsing Product team use cases'
      : 'Отображаются примеры по Продукту';
  const browsingAsHR =
    locale === 'en'
      ? 'You’re browsing HR use cases'
      : 'Отображаются примеры по HR';

  useEffect(() => {
    if (isSwitched !== undefined) {
      if (isProductView) {
        setSnackBarText(browsingAsProduct);
      } else {
        setSnackBarText(browsingAsHR);
      }
    }
  }, [isSwitched, isProductView, locale]);

  let snackbarTimeout: NodeJS.Timeout;
  const handleSnackbarOpening = () => {
    clearTimeout(snackbarTimeout);

    setShowSnackbar(true);
    snackbarTimeout = setTimeout(() => {
      setShowSnackbar(false);
    }, 2000);
    return () => clearTimeout(snackbarTimeout);
  };

  if (!isLoaded) {
    return <Spinner visible={true} />;
  }

  return (
    <div
      className={cn(styles.body, {
        [styles.openedModal]: biasSelected,
      })}
    >
      {!isUxcoreMobile && (
        <>
          <ViewSwitcher
            isSecondView={isCoreView}
            toggleIsCoreView={toggleIsCoreView}
            defaultViewLabel="core"
            defaultVieWIcon={<CoreIcon />}
            secondViewLabel={'folder'}
            secondViewIcon={<FolderIcon />}
            className={styles.viewTypeSwitcher}
            labelViewType
          />
          <ViewSwitcher
            isSecondView={isProductView}
            toggleIsCoreView={toggleIsProductView}
            defaultViewLabel={productViewLabel}
            defaultVieWIcon={<ProductIcon />}
            secondViewLabel={'hr'}
            secondViewIcon={<HrIcon />}
            className={styles.viewTeamSwitcher}
            setIsSwitched={setIsSwitched}
            isSwitched={isSwitched}
            handleSnackbarOpening={handleSnackbarOpening}
          />
          {isCoreView && <Search biases={strapiBiases} />}
          {isCoreView && (
            <>
              <CoreViewLayout styles={styles} biases={strapiBiases} />
              {openPodcast && (
                <UXCorePopup
                  setOpenPodcast={setOpenPodcast}
                  openPodcast={openPodcast}
                />
              )}
              <Logos className={styles.Logos} />
            </>
          )}
          {!isCoreView && (
            <FolderViewLayout biases={strapiBiases} isOpen={isOpen} />
          )}
        </>
      )}
      <div className={styles.MobileView}>
        <MobileView
          isSecondView={isProductView}
          toggleIsCoreView={toggleIsProductView}
          defaultViewLabel={productViewLabel}
          secondViewLabel={'hr'}
          tags={tags}
          strapiBiases={strapiBiases}
          containerClassName={styles.body}
          setIsSwitched={setIsSwitched}
          isSwitched={isSwitched}
          isOpen={isOpen}
          biasSelected={biasSelected}
          headerPodcastOpen={setHeaderPodcastOpen}
          isPodcastOpen={headerPodcastOpen}
          handleSnackbarOpening={handleSnackbarOpening}
          description={description}
          userInfo={userInfo}
          setUserInfo={setUserInfo}
        />
        <div>
          {headerPodcastOpen && (
            <UXCorePopup
              setOpenPodcast={setHeaderPodcastOpen}
              openPodcast={headerPodcastOpen}
            />
          )}
        </div>
      </div>
      <ToolFooter page="uxcore" tags={tags} />
      {!!snackBarText && (
        <UXCoreSnackbar text={snackBarText} showSnackbar={showSnackbar} />
      )}
    </div>
  );
};

export default UXCoreLayout;
